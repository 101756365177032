<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="back()">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Systemlogisticsnode:Get'">新增</el-button> </div>
                <div class="h-b"><el-button type="text" size="small" @click="saveRuleOfLogisticsNode" v-right-code="'Systemlogisticsnode:Get'">保存</el-button> </div>
            </div>
        </div>

        <el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tab1Click">
            <el-tab-pane label="基本信息" name="tabRuleOfLogisticsNode">
                <div class="form-list">
                    <el-form ref="_ruleOfLogisticsNodeForm" :model="dataSource" :rules="projectCheckRule">
                        <el-form-item>
                            <el-col :span="4" class="form-title"><span style="color:red;">*</span>物流订单类型：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="OrderOperationType">
                                    <DictionarySelect v-model="dataSource.OrderOperationType" dicTypeCode="LogisticsOrderTypeCode" datType="Int"></DictionarySelect>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="4" class="form-title"><span style="color: red">*</span>项目名称：</el-col>
                            <el-col :span="8">
                                <el-form-item prop="ProjectName">
                                    <!-- <el-select v-model="dataSource.ProjectCode" filterable placeholder="请选择" @change="projectChange()">
                                        <el-option v-for="item in projectList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select> -->
                                    <el-input type="input" v-model="dataSource.ProjectName" @focus="orgControlShow" suffix-icon="el-icon-search" readonly></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4" class="form-title"><span style="color:red;">*</span>状态：</el-col>
                            <el-col :span="8">
                                <el-radio-group v-model="dataSource.Status">
                                    <el-radio :label="100">启用</el-radio>
                                    <el-radio :label="200">停用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-tabs class="done" type="border-card" v-model="activeName1">
            <el-tab-pane label="状态匹配信息" name="tabdetailinfo">
                <div class="form-padding h-handle-table h-table-list">
                    <div class="h-handle-button h-handle-bg">
                        <el-button type="text" size="small" @click="addDetail(0)">新增</el-button>
                    </div>
                    <div class="table-s">
                        <el-table :data="dataSource.LogisticsNodeDetailList" border highlight-current-row>
                            <el-table-column prop="OrderType" label="下游作业类型">
                                <template slot-scope="scope">
                                    <el-col :span="15">
                                        <!-- <el-input v-model="scope.row.OrderType" size="mini" :disabled="scope.row.IsDisabled" placeholder="下游作业类型" @blur="checkMemberDetail(0,scope.$index,scope.row.OrderType)" /> -->
                                        <el-select v-model="scope.row.OrderType" size="mini" :disabled="scope.row.IsDisabled" placeholder="下游作业类型" @blur="checkMemberDetail(0,scope.$index,scope.row.OrderType)">
                                            <el-option v-for="item in detailTypeOptions"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </template>
                            </el-table-column>
                            <el-table-column prop="OrderStatus" label="下游作业状态">
                                <template slot-scope="scope">
                                    <el-col :span="15">
                                        <!-- <el-input v-model="scope.row.OrderStatus" size="mini" :disabled="scope.row.IsDisabled" placeholder="下游作业状态" @blur="checkMemberDetail(1,scope.$index,scope.row.OrderStatus)" /> -->
                                        <el-select v-model="scope.row.OrderStatus" size="mini" :disabled="scope.row.IsDisabled" placeholder="下游作业状态" @blur="checkMemberDetail(1,scope.$index,scope.row.OrderStatus)">
                                            <el-option v-for="item in detailStatusOptions"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </template>
                            </el-table-column>
                            <el-table-column prop="NodeStatus" label="物流节点状态">
                                <template slot-scope="scope">
                                    <el-col :span="15">
                                        <!-- <el-input v-model="scope.row.NodeStatus" size="mini" :disabled="scope.row.IsDisabled" placeholder="物流节点状态" @blur="checkMemberDetail(2,scope.$index,scope.row.NodeStatus)" /> -->
                                        <el-select v-model="scope.row.NodeStatus" size="mini" :disabled="scope.row.IsDisabled" placeholder="物流节点状态" @blur="checkMemberDetail(2,scope.$index,scope.row.NodeStatus)">
                                            <el-option v-for="item in detailNodeStatusOptions"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </template>
                            </el-table-column>
                            <el-table-column prop="NodeStatus" label="是否外部展示">
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.ShowStatus"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="120">
                                <template slot-scope="scope">
                                    <!-- <el-button @click="saveContact(scope.row)" v-if="!scope.row.IsDisabled&&isDisabled" v-right-code="'Manage_Right_Commerce_ShipProvider_AddShipProviderDetail'" size="small" type="text">保存</el-button> -->
                                    <el-button @click="saveContact(scope.row)" v-if="!scope.row.IsDisabled&&isDisabled" size="small" type="text">保存</el-button>
                                    <el-button @click="editDetail(scope.row)" v-if="scope.row.IsDisabled&&isDisabled" size="small" type="text">编辑</el-button>
                                    <el-button @click="cancelDetail(scope.row)" size="small" type="text" v-if="!scope.row.IsDisabled&&scope.row.Id!=null">取消</el-button>
                                    <!-- <el-button @click="removeDetail(scope.row)" size="small" type="text" v-if="scope.row.Id==Utils.emptyGuid||(scope.row.Id!=Utils.emptyGuid&&scope.row.IsDisabled)" v-right-code="'Manage_Right_Commerce_ShipProvider_DeleteShipProviderDetail'">删除</el-button> -->
                                    <el-button @click="removeDetail(scope.row)" size="small" type="text" v-if="scope.row.Id==null||(scope.row.IsDisabled&&isDisabled)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

        <DialogEx :options="controlConfig.viewOptions" title="组织机构" @onOk="onOrgControlOk">
			<OrgControl ref="orgControl" :config="controlConfig.data" @onSelectedRow="onOrgControlSelectedRow">
			</OrgControl>
		</DialogEx>
    </div>
</template>

<script>
    export default {
        data() {
            // var checkMemberName = (rule, value, callback) => {
            //     if (!value) return callback(new Error('物流节点模板名称不能为空'));
            //     callback();
            // };
 var checkProjectCode = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("请选择项目"));
      callback();
    };

     var checkEffectiveDate = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("生效日期不能为空"));
      callback();
    };

     var checkExpiredDate = function (rule, value, callback) {
      if (value == null || value.length == 0)
        return callback(new Error("失效日期不能为空"));
      callback();
    };


            return {
                ContactPersons: [],
                multipleSelection: [],
                projectList:[],
                tab2activeName: 'taborderitem',
                isDisabled: false,
                activeName: 'tabRuleOfLogisticsNode',
                activeName1: 'tabdetailinfo',
                CancalDetailInfo: [],
                expressOptions:[],
                projectCheckRule: {
                    ProjectName: [{ required: true, message: '请选择项目', trigger: 'blur' }],
                    OrderOperationType: [{ required: true, message: '请选择物流订单类型', trigger: 'change' }]
                },
                detailTypeOptions: [
                    { label: '出库', value: 100 },
                    { label: '入库', value: 200 },
                    { label: '运输', value: 300 }
                ],
                detailStatusOptions: [
                    { label: '已排线', value: 10 },
                    { label: '已指派', value: 20 },
                    { label: '已装货', value: 30 },
                    { label: '配送中', value: 40 },
                    { label: '已签收', value: 50 },
                    { label: '发货出库', value: 100 },
                    { label: '收货入库', value: 200 }
                ],
                detailNodeStatusOptions: [
                    { label: '02-仓库入库', value: 2 },
                    { label: '03-仓库出库', value: 3 },
                    { label: '04-配送派车', value: 4 },
                    { label: '05-配送装货', value: 5 },
                    { label: '06-配送发车', value: 6 },
                    { label: '07-配送在途', value: 7 },
                    { label: '08-配送签收', value: 8 },
                    { label: '09-回单确认', value: 9 }

                    // { label: '01-提货确认', value: 1 },
                    // { label: '02-提货派车', value: 2 },
                    // { label: '03-提货完成', value: 3 },
                    // { label: '04-提货发车', value: 4 },
                    // { label: '05-提货在途', value: 5 },
                ],
                controlConfig: {
                    viewOptions:{
                        visible: false,
                        size: 'normal',
                        customClass: 'small-dialog'
                    },
                    data:{
                        dataType:1,
                    }
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {
                LogisticsNodeDetailList: []
            }
        },
        mounted() {
            this.Event.$on("clearEditRuleOfLogisticsNodeForm", () => this.resetForm);
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    this.isDisabled = this.Utils.isNotNull(this.dataSource.Id);
                },
                deep: true
            }
        },
        created: function () {
            this.Event.$on("clearForm", this.resetForm);
        },
        methods: {
            // checkMemberName(rule, value, callback) {
            //     if (!value) return callback(new Error('物流节点模板名称不能为空'));
            //     else {
            //         this.$ajax.send("omscc_api/ruleOfLogisticsNode/checkshipprovidernameexist", "post", this.dataSource, (data) => {
            //             if (data.OperationDesc == "名称已存在") {
            //                 return callback(new Error('物流节点模板名称已存在'));
            //             }
            //         });
            //     }
            //     callback();
            // },
            addDetail: function () {//添加明细
                var _this = this;
                var newRow = {
                    RuleId: _this.dataSource.Id,
                    OrderType: '',
                    OrderStatus: '',
                    NodeStatus: '',
                    Id: null,
                    IsDisabled: false
                };
                _this.dataSource.LogisticsNodeDetailList.push(newRow);
            },
            editDetail: function (row, index, ) {//编辑明细
                var _this = this;
                row.IsDisabled = false;
                if (_this.Utils.isNotNull(row.Id) && _this.Utils.isNotNull(row.RuleId)) {
                    _this.CancalDetailInfo.OrderType = row.OrderType;
                    _this.CancalDetailInfo.OrderStatus = row.OrderStatus;
                    _this.CancalDetailInfo.NodeStatus = row.NodeStatus;
                    // _this.CancalDetailInfo.DetailID = row.DetailID;
                }
            },
            saveContact: function (row, index) { //保存明细
                var _this = this;
                 
                if (row.OrderType == '' || row.OrderStatus == '') {
                    _this.Utils.messageBox("下游作业类型或者下游作业状态不能为空.", "warning");
                    return false;
                }

                if (_this.Utils.isNotNull(_this.dataSource.Id)) {
                    _this.$ajax.send("omscc_api/ruleOfLogisticsNode/saveOrUpdateDetail", "post", row, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.isNull(row.Id) && (row.Id = data.OperationDesc);
                            _this.Utils.messageBox("保存明细成功.", "success");
                            row.IsDisabled = true;
                        }
                        else {
                            _this.Utils.messageBox("保存明细失败，清重试.", "warning");
                        }
                    });
                }
                else {
                    row.IsDisabled = true;
                }
            },
            removeDetail: function (row, index) {//移除明细
                var _this = this;
                if (_this.Utils.isNotNull(_this.dataSource.Id) && _this.Utils.isNotNull(row.Id)) {
                    _this.$ajax.send("omscc_api/ruleOfLogisticsNode/deleteDetail", "post", row, (data) => {
                        if (data.IsSuccess) {
                            _this.Utils.messageBox("移除明细成功.", "success");
                            this.dataSource.LogisticsNodeDetailList.remove(row);
                        }
                        else {
                            _this.Utils.messageBox("移除明细失败，清重试.", "warning");
                        }
                    });
                }
                else {
                    this.dataSource.LogisticsNodeDetailList.remove(row);
                }
            },
            cancelDetail: function (row, index) {//取消添加明细
                var _this = this;
                if (_this.Utils.isNotNull(_this.dataSource.Id) && _this.Utils.isNotNull(row.Id)) {
                    row.OrderType = _this.CancalDetailInfo.OrderType;
                    row.OrderStatus = _this.CancalDetailInfo.OrderStatus;
                    row.NodeStatus = _this.CancalDetailInfo.NodeStatus;
                    // row.DetailID = _this.CancalDetailInfo.DetailID;
                }
                row.IsDisabled = true;
            },
            tab1Click: function (tab, event) {
            },
            back() {
                this.config.isDetailDisplay = false;
            },
            add() {
                this.Event.$emit(this.config.moduleEvent.add);
            },
            resetForm: function (memberId) {
                !this.isPassValidate && this.$refs['_ruleOfLogisticsNodeForm'].resetFields();//清空表单
            },
            saveRuleOfLogisticsNode() {
                var _this = this;
                var list = _this.dataSource.LogisticsNodeDetailList;
                if (list != null && list != []) {
                    for (var i in list) {
                        if (list[i].OrderType == "" || list[i].OrderStatus == "" || list[i].NodeStatus == "") {
                            _this.Utils.messageBox("物流节点模板明细信息不全", "error");
                            return false;
                        }
                    }
                }

                _this.$refs["_ruleOfLogisticsNodeForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        this.$ajax.send("omsapi/systemlogisticsnode/save", "post", _this.dataSource, (data) => {
                            _this.$parent.syncDataSource(data.Result);
                            _this.Event.$emit(_this.config.moduleEvent.initial, data.Result);
                            _this.Utils.messageBox("保存成功.", "success");                            
                        });
                    } else {
                        return false;
                    }
                });
            },
            checkMemberDetail: function (sign, index, val) {

            },
            orgControlShow(){
				this.controlConfig.viewOptions.visible=true;
			},
			onOrgControlOk(){
				var data = this.$refs.orgControl.getSelectedAll();
                if(data.ProjectList && data.ProjectList.length>0)
                {
                    var project = data.ProjectList[0];
                    this.dataSource.ProjectCode=project.ProjectCode;
                    this.dataSource.ProjectName=project.ProjectName;
                }
            },
            onOrgControlSelectedRow(){
            }
        },
        components: {
            "OrgControl": resolve => { require(['../../../components/orgcontrol.vue'], resolve) },
        }
    }

</script>
